.ResumeComite {
	display: flex;
}
.boxResumeComite {
	flex: 1;
	min-height: 85px;
}
.numberResumen {
	position: absolute;
	left: 50%;
	top: 100%;
	transform: translate(-50%, -100%);
	font-size: 30px;
}
.editNew {
	display: none;
}
.hidden {
	display: none;
}

.warning-container {
	margin-bottom: 15px;
}

.image-warning {
	font-size: 14px;
	font-style: italic;
}
