.bannerAD{
    background-color:#0058a1;
    padding: 20px;
    color: #FFF;
}

.buttonAD{
    float: right;
    background: #ffffff;
    color: #343a40 !important;
    letter-spacing: 0.5px;
    position: relative;
    overflow: hidden;
    margin-bottom: 6px;
    outline: 0;
    white-space: nowrap;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 2px solid transparent;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.6;
    border-radius: 3px;
    align-self: center;
}
.buttonAD:hover{
    background: #ffffff;
    color: #343a40 !important;
}
.titleAD{
    font-size: 1.53125rem;
    font-weight: 500;
    line-height: 1.2;
    color: #FFF;
}
.subtitleAD{
    color: #FFF;
}

@media only screen and (max-width: 991px) {
    .buttonAD{
        float: none;
        width: 100%;;
    }
  }