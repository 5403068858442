@import url(https://fonts.googleapis.com/css?family=Muli);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700,900);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



footer {
    background-color: #343a40;
    color: #fff;
}

.footer-logo-slider {
    max-width: 200px;
}

.slide h1 {
	transition: all 0.3s ease;
	-webkit-transform: translateY(-20px);
					transform: translateY(-20px);
	opacity: 0;
}

.slide button {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}
.slide input {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}
.slide .searchSlider {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}
.slide p {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide section * {
	transition: all 0.3s ease;
}

.slide section img {
	-webkit-transform: translateX(-10px);
					transform: translateX(-10px);
	opacity: 0;
}

.slide section span {
	-webkit-transform: translateY(-10px);
					transform: translateY(-10px);
	opacity: 0;
}

.slide section span strong {
	-webkit-transform: translateY(10px);
					transform: translateY(10px);
	opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous input,
.slide.current input,
.slide.animateIn.next input,
.slide.animateIn.previous .searchSlider,
.slide.current .searchSlider,
.slide.animateIn.next .searchSlider,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	transition-delay: .9s;
					opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
	transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
	transition-delay: 1.3s;
}
.slide.animateIn.previous input,
.slide.animateIn.next input {
	transition-delay: 1.3s;
}
.slide.animateIn.previous .searchSlider,
.slide.animateIn.next .searchSlider {
	transition-delay: 1.3s;
}
.slide.animateIn.previous section img,
.slide.animateIn.next section img {
	transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
	transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
	transition-delay: 1.5s;
}

.slide.animateOut h1 {
	transition-delay: .3s;
}

.slide.animateOut p {
	transition-delay: .2s;
}

.slide.animateOut section span {
	transition-delay: .1s;
}

.slide.animateOut section span strong {
	transition-delay: 0s;
}
body {
	font-family: "Lato", sans-serif;
	box-sizing: border-box;
	padding-top: 75px;
}

header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 75px;
	background: #000000;
	z-index: 10;
}

header section {
	max-width: 1300px;
	margin: auto;
	padding: 20px;
	background: #ffd800;
}

@media (max-width: 1515px) {
	header section {
		max-width: 100%;
	}
}

header section .logo {
	-webkit-transform: translateX(-87px);
	        transform: translateX(-87px);
}

header section a {
	float: right;
	padding: 3px 0;
}

* {
	outline: none;
}

button {
	-webkit-appearance: none;
	appearance: none;
	-webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
	filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
	-webkit-transition: all 0.5s ease;
	filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
	transition: all 0.5s ease;
	border: none;
	background: #ffd800;
	border-radius: 30px;
	text-transform: uppercase;
	box-sizing: border-box;
	padding: 15px 40px;
	font-weight: 400;
	font-size: 13px;
	cursor: cursor;
}

button:hover {
	color: #ffffff;
	background: #222222;
	-webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
	filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}
.searchSlider {
	background-color: #fff;
	padding: 5px;
	border-radius: 3px;
}
.formSearchSlider {
	max-width: 600px;
	align-items: center;
	text-align: center;
	margin: 0 auto;
	width: auto;
}
.searchBarHome {
	width: 60%;
	height: 41px;
	border: 0px;
}
.buttonSearchSlider {
	width: 25%;
	margin-bottom: 0px !important;
	float: right;
}
.wrapper {
	max-width: 1335px;
	box-sizing: border-box;
	padding: 0 20px;
	margin: auto;
}

.wrapper a {
	display: inline-block;
	margin: 5px;
}

.wrapper a:first-child {
	margin-left: 0;
}

.slider-wrapper {
	position: relative;
	height: 70vh;
	overflow: hidden;
}

.slide {
	height: 70vh;
	background-size: cover !important;
}

.slide::before {
	content: "";
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
	bottom: 0;
	left: 0;
}

.previousButton,
.nextButton {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	z-index: 10;
	background: url(https://svgshare.com/i/41_.svg) no-repeat center center / 16px;
	width: 40px;
	height: 50px;
	text-indent: -9999px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.47);
}

.previousButton:hover,
.nextButton:hover {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	z-index: 10;
	background: url(https://svgshare.com/i/41_.svg) no-repeat center center / 16px;
	width: 40px;
	height: 50px;
	text-indent: -9999px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.47);
}

.previousButton {
	left: 0;
	-webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
	transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
	left: -10px;
}

.nextButton {
	right: 0;
}

.nextButton:hover {
	right: -10px;
}

.slider-content {
	text-align: center;
	width: 100%;
	height: 100%;
}

.slider-content .inner {
	padding: 0 70px;
	box-sizing: border-box;
	position: absolute;
	width: 100%;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.slider-content .inner button {
	transition-delay: 0s;
}

.slider-content .inner h1 {
	font-weight: 900;
	margin: 0 auto;
	max-width: 840px;
	color: #ffffff;
	font-size: 64px;
	line-height: 1;
}

.slider-content .inner p {
	color: #ffffff;
	font-size: 14px;
	line-height: 1.5;
	margin: 20px auto 30px;
	max-width: 640px;
}

.slider-content section {
	position: absolute;
	bottom: 20px;
	left: 20px;
}

.slider-content section span {
	color: #ffffff;
}

.slider-content section span {
	color: rgba(255, 255, 255, 0.5);
	font-size: 12px;
	display: inline-block;
	text-align: left;
	line-height: 1.4;
	vertical-align: middle;
	margin-left: 10px;
}

.slider-content section img {
	width: 40px;
	height: 40px;
	border: solid 2px rgba(255, 255, 255, 0.5);
	border-radius: 100%;
	vertical-align: middle;
}

.slider-content section span strong {
	color: #ffffff;
	font-size: 14px;
	display: block;
}

@media (max-height: 500px) {
	.slider-wrapper,
	.slide {
		height: calc(100vh - 75px);
	}
}

@media (max-width: 640px) {
	.slider-wrapper,
	.slide {
		height: calc(80vh - 75px);
	}
}

@media (max-height: 600px) {
	.slider-content .inner h1 {
		font-size: 32px;
	}
}

@media (max-width: 640px) {
	.slider-content .inner h1 {
		font-size: 32px;
	}
}

@media (max-width: 800px) {
	.buttonSearchSlider {
		width: 40%;
	}
}

.top3{
    margin-top: -60px;
}
.top3-row{
    padding: 3rem;
}
.imgTop3{
    width: 100%;
    align-self: center;
}


.bannerAD{
    background-color:#0058a1;
    padding: 20px;
    color: #FFF;
}

.buttonAD{
    float: right;
    background: #ffffff;
    color: #343a40 !important;
    letter-spacing: 0.5px;
    position: relative;
    overflow: hidden;
    margin-bottom: 6px;
    outline: 0;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
            user-select: none;
    border: 2px solid transparent;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.6;
    border-radius: 3px;
    align-self: center;
}
.buttonAD:hover{
    background: #ffffff;
    color: #343a40 !important;
}
.titleAD{
    font-size: 1.53125rem;
    font-weight: 500;
    line-height: 1.2;
    color: #FFF;
}
.subtitleAD{
    color: #FFF;
}

@media only screen and (max-width: 991px) {
    .buttonAD{
        float: none;
        width: 100%;;
    }
  }
body {
	font-family: "Montserrat", sans-serif;
	margin: 0;
	font-size: 0.88rem;
	font-weight: 400;
	line-height: 1.2;
	color: #8f9397;
	text-align: left;
	background-color: #ffffff;
}

section {
	padding-top: 5rem;
	padding-bottom: 3.8rem;
}
.breadcrumbNotice span {
	text-transform: capitalize !important;
}
p {
	font-family: "Montserrat", sans-serif !important;
	font-size: 0.88rem;
	font-weight: 400;
	line-height: 1.6;
	color: #333333;
}
.lastMinuteNews .inner p,
.lastMinuteNews .inner a {
	word-break: break-word;
}
span {
	font-family: "Montserrat", sans-serif !important;
}
ul li,
ol li {
	font-family: "Montserrat", sans-serif !important;
	font-size: 0.88rem;
	font-weight: 400;
	line-height: 1.6;
	color: #333333;
}

a {
	color: #0058a1;
	text-decoration: none;
	background-color: transparent;
}

a:hover {
	color: #dba13d;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: 0.5rem;
	font-family: "Montserrat", sans-serif;
	font-weight: 500;
	line-height: 1.2;
	color: #343a40;
}
footer p,
footer a {
	color: #d5d5d5;
	font-size: 0.875rem;
}
.ant-pagination {
	margin-top: 15px;
}
.tabContent {
	margin-top: 10px;
}
.lighGrayBG {
	background-color: #f7f8f9 !important;
}
.menuTopFES .show {
	background-color: #f9f9f9;
}
.menuTopFES .navbar-brand {
	max-width: 180px;
}
.menuTopFES .logoHeader {
	width: 100%;
}
.align-items-center {
	align-items: center !important;
}

.h-sm-100-vh {
	height: 100vh;
}

.fes-blue-bg {
	background-color: #012d9a;
}

.maxWidth-100 {
	max-width: 100% !important;
}

.nopadding {
	padding: 0px !important;
}

.title-comites {
	font-size: 2.1875rem;
	font-weight: 600;
	color: white;
}

.bg-light {
	background-color: #f7f8f9 !important;
}

h2 {
	font-weight: 600;
	margin-bottom: 10px;
	line-height: 1.2;
	color: #343a40;
}

h2,
.h2 {
	font-size: 1.75rem;
}

h3 {
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
	color: #343a40;
}

.h3,
h3 {
	font-size: 1.75rem;
}

h5,
.h5 {
	font-size: 1.09375rem;
}
.ant-pagination-options {
	display: none;
}
header {
	z-index: 99;
	background-color: #fff;
	height: 116px;
}
.hiddenCustom {
	display: none;
}
.ctaHeader {
	padding-right: 0.7rem !important;
	padding-left: 0.7rem !important;
}
.separator {
	color: #8f9397;
	width: 100%;
	margin: 0px auto;
	overflow: hidden;
	text-align: center;
	line-height: 0em;
}

.memberComite {
	padding-top: 15px;
	padding-bottom: 5px;
}

.memberComite p {
	margin-bottom: 0.3rem;
}

.bg-grad-blue {
	background: linear-gradient(150deg, #0058a1 0%, #1d4560 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two', GradientType=1);
}

.separatorGrad:before {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	content: "";
	display: inline-block;
	height: 0em;
	margin: 0 0 0 -100%;
	vertical-align: middle;
	width: 50%;
	height: 3px;
	background: linear-gradient(150deg, #0058a1 0%, #1d4560 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two', GradientType=1);
}

.separatorGrad:after {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	content: "";
	display: inline-block;
	height: 0;
	margin: 0 0 0 -100%;
	vertical-align: middle;
	width: 50%;
	margin: 0 -100% 0 0;
	height: 3px;
	background: linear-gradient(150deg, #1d4560 0%, #0058a1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two', GradientType=1);
}
.captcha {
	-webkit-transform: scale(0.77) !important;
	        transform: scale(0.77) !important;
	-webkit-transform-origin: 0 0 !important;
	        transform-origin: 0 0 !important;
}
.textColorSecondary {
	color: #007bff;
}

.imageBanner img {
	width: 100%;
}
.fieldFormNL {
	height: 35px;
	padding: 0.375rem 0.3rem;
}
.formSubscriptionNews .form-group {
	margin-bottom: 0.25rem;
}
.formSubscriptionNews button {
	margin-top: -20px;
}
.customSmallSpace {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}
.menuItem {
	text-transform: uppercase;
	font-size: 0.9rem !important;
}

.linkMenuTop {
	color: #898989 !important;
}

.navbar-light .navbar-nav .show > .nav-link {
	color: #898989 !important;
}
.menuTopFES button:hover {
	background-color: white;
}
.menuTopFES .nav-link:hover,
.menuTopFES .dropdown-item a:hover,
.menuTopFES .InsideDropdown a:hover,
.linkMenuTop:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: #0058a1 !important;
	text-decoration: none;
}

.navbar-expand-lg .navbar-nav .nav-link {
	padding-right: 0.7rem !important;
	padding-left: 0.7rem !important;
}

.menuItem .dropdown-menu {
	text-transform: none;
}

.comiteBox .inner {
	padding: 10px;
	border: solid 2px rgba(0, 0, 0, 0.1);
	border-bottom-width: 5px;
	transition: all 0.5s ease-in-out;
}

.comiteBox h4 {
	font-size: 0.9875rem;
}

.comiteBox {
	margin: 10px;
}

.comiteListing {
	display: flex;
}
.boxResumeProducts .precio {
	margin-bottom: 10px;
}
.ctaButton {
	color: white;
	text-decoration: none;
}
.ctaButton:hover {
	text-decoration: none;
	/*background: #ffffff;*/
}
.ctaButton:active {
	color: white;
}
/*.comiteLevel01{
    flex: 1!important;
}*/
.video-responsive {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.video-responsive iframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

.comiteBox .inner:hover {
	border-color: #0058a1;
	box-shadow: 0px 0px 20px rgba(83, 88, 93, 0.1);
}

.backgroundImage {
	background-size: cover !important;
	background-position: center !important;
	background-repeat: no-repeat !important;
}

.blogImage {
	max-width: 100%;
	max-height: 700px;
	height: auto;
	vertical-align: middle;
	border-style: none;
}

.noticeTitle {
	min-height: 242px;
}

.centerTitleBlog {
	padding-top: 85px;
}

.subtitleComite {
	padding-bottom: 25px;
}

.leftTitle {
	padding-top: 85px;
	text-align: left;
	padding-bottom: 20px;
}

.zoom-on-hover:hover {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}

.whiteSpace {
	margin-top: 2rem;
	margin-bottom: 2rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0);
}
.goBacklink:hover {
	text-decoration: none;
}
.whiteSpaceMedium {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0);
}

.noticeTitle h1 {
	font-size: 2.1875rem;
	font-weight: 600;
	color: #ffffff;
	text-transform: uppercase;
}

.breadcrumbNotice {
	color: #ffffff;
}
.breadcrumbNotice a {
	color: white;
	text-decoration: none;
}
.breadcrumbNotice a:hover {
	color: white;
	text-decoration: none;
}
.topmenuScroll {
	transition: top 0.6s;
}

.topmenuScroll--hidden {
	margin-top: -40px;
	transition: top 0.6s;
}

.textWhite {
	color: #fff !important;
}

.noPadding {
	padding: 0px !important;
}

.form-login {
	max-width: 500px;
}

.mx-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

.listCheck ul {
	list-style: none;
}

.listCheck ul li:before {
	content: "✓";
}

.login-padding {
	padding: 3rem !important;
}

.d-flex {
	display: flex !important;
}

.topMenuRight,
.topMenuLeft {
	height: 40px;
	font-size: 0.8125rem;
}

.topMenuLeft {
	padding-top: 5px;
}

.topMenuRight {
	margin-top: -3px;
}

.topMenuRight .nav-link,
.topMenuRight a {
	color: #fff !important;
}

.new-cat {
	margin-bottom: 30px;
}

.topMenuRight .nav-link:hover,
.topMenuRight a:hover {
	color: #0058a0 !important;
}

.dropdown-item a {
	color: #8f9397 !important;
}

.categoryBlock {
	margin-bottom: 20px;
}

.headerMenu {
	height: 120px;
	background-color: white;
}

.topMenu {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 0.2rem 0;
	background-color: #e79f18;
	height: 40px;
}

.margin-header {
	margin-top: 45px;
}
.margin-top-sidebar {
	margin-top: 15px;
}
.number-under-content {
	position: absolute;
	left: 0;
	top: 0;
	font-size: 100px;
	font-weight: bolder;
	color: rgba(0, 0, 0, 0.1);
	z-index: -1;
}

.shadow {
	box-shadow: 0px 0px 30px rgba(83, 88, 93, 0.15) !important;
}

.separation-15 {
	padding-right: 15px;
	padding-left: 15px;
}

.padding-20 {
	padding: 20px;
}

.padding-20 {
	padding: 20px;
}

.padding-10 {
	padding: 10px;
}

.margin-10 {
	margin: 10px;
}

#page-numbers {
	list-style: none;
	cursor: pointer;
}

#page-numbers > span {
	margin-right: 0.3em;
	color: #012d9a;
	-webkit-user-select: none;
	        user-select: none;
	cursor: pointer;
	float: left;
}
.tabsTemplate {
	display: flex;
	flex-wrap: nowrap;
	align-items: stretch;
	margin: 0;
	padding: 0;
}
.tabsTemplate a[role] {
	flex: 1 1;
	text-align: center;
}
.height-100 {
	height: 100%;
}

.searchMembers {
	border-bottom: 1px solid #012d9a;
}

.listingUsers {
	padding: 5px;
	margin-bottom: 15px;
}

.socialIconsComite {
	margin-right: 5px;
	margin-left: 5px;
}

.border-radius-3 {
	border-radius: 3px !important;
}

.center {
	text-align: center;
}

.align_center {
	align-self: center;
}

.subtitle {
	margin-bottom: 50px;
}
.categories-button .false {
	color: #0058a1;
	background: #ffffff;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two', GradientType=1);
	transition: all 0.3s ease-in-out;
	background-size: 260% 100%;
	background-position: right bottom;
	line-height: 1.92;
	letter-spacing: 0.5px;
	position: relative;
	overflow: hidden;
	margin-bottom: 6px;
	outline: 0;
	white-space: nowrap;
	transition: all 0.3s ease-in-out;
	display: inline-block;
	font-weight: 500;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	        user-select: none;
	border: 2px solid #0058a1;
	padding: 0.5rem 1rem;
	font-size: 11px;
	line-height: 1.6;
	border-radius: 3px;
}
.cleanFilter:hover {
	word-wrap: break-word;
	color: #ffffff;
	background: linear-gradient(150deg, #0058a1 0%, #1d4560 50%, #0058a1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two', GradientType=1);
	transition: all 0.3s ease-in-out;
	background-size: 260% 100%;
	background-position: right bottom;
	border: 0;

	position: relative;
	overflow: hidden;

	outline: 0;
	transition: all 0.3s ease-in-out;
	display: inline-block;

	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	user-select: none;
	border: 2px solid transparent;
}
.button {
	cursor: pointer;
	word-wrap: break-word;
	color: #ffffff;
	background: linear-gradient(150deg, #0058a1 0%, #1d4560 50%, #0058a1 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two', GradientType=1);
	transition: all 0.3s ease-in-out;
	background-size: 260% 100%;
	background-position: right bottom;
	border: 0;
	line-height: 1.92;
	letter-spacing: 0.5px;
	position: relative;
	overflow: hidden;
	margin-bottom: 6px;
	outline: 0;
	transition: all 0.3s ease-in-out;
	display: inline-block;
	font-weight: 500;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	        user-select: none;
	border: 2px solid transparent;
	padding: 0.5rem 1rem;
	font-size: 0.875rem;
	line-height: 1.6;
	border-radius: 3px;
}

.categoryNewsTop .button {
	padding: 0.2rem 0.2rem;
	font-size: 0.8rem;
	line-height: 1;
	max-width: 225px;
	margin-right: 10px;
}

.seeVideo .button {
	position: absolute;
	bottom: 0px;
	left: 0px;
	margin-left: -20px;
}

.button:hover {
	color: #ffffff !important;
	background-position: left bottom;
	text-decoration: none;
	background: linear-gradient(150deg, #0058a1 0%, #1d4560 50%, #0058a1 100%);
}

.button a {
	color: #fff;
	text-decoration: none;
}

.button a:hover {
	color: #fff;
	text-decoration: none;
}
.linkStyle {
	color: #007bff;
	cursor: pointer;
}
.linkStyle:hover {
	color: #0056b3;
	cursor: pointer;
}
.socialShare {
	margin-bottom: 10px;
}
.separatorNew {
	margin-top: 25px;
	margin-bottom: 25px;
}
.title-feature {
	font-size: 22px;
	font-weight: 500;
	line-height: 26px;
	margin-bottom: 10px;
	color: #343a40;
	text-align: left;
	margin-top: 10px;
	/*	text-transform: lowercase;*/
}
.boxResumeProducts {
	padding: 10px;
	background-color: #fbfbfb;
	border-radius: 3px;
	margin: 5px 0px;
	height: 100%;
}
.boxResumeProducts .button {
	width: 100%;
}
.RowIndexProducts .columnIndexProducts {
	padding-top: 15px;
}
.listCategory {
	margin-top: 20px;
}
/*
.shortDescription,
.monthlyTitle {
	text-transform: lowercase;
}
*/
.titulobusqueda {
	font-size: 1rem;
}
.featuredNews .boxResumeNews p {
	line-height: 1.1;
}
.featuredNews .boxResumeNews h5 {
	margin-bottom: 15px;
}
.boxResumeNews h6 {
	font-size: 12px;
}
.shortDescription {
	line-height: 1.1;
	font-size: 0.88rem;
	color: #333;
	margin-top: 10px;
}
.title-feature::first-letter,
.shortDescription::first-letter,
.monthlyTitle::first-letter {
	text-transform: capitalize;
}
.shortDescription {
	word-break: break-word;
}
.top3 .title-feature {
	text-align: center;
}
.boxAsociaciones {
	margin-bottom: 10px;
}

.boxAsociaciones img {
	max-width: 75px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.boxAsociaciones h6 {
	text-transform: uppercase;
}

.mediaContent {
	width: 100%;
}

.width-100 {
	width: 100%;
}

.max-width-100 {
	max-width: 100% !important;
}

.max-width-site {
	box-sizing: border-box;
	position: relative;
	margin-left: auto !important;
	margin-right: auto !important;
	padding-left: 15px;
	padding-right: 15px;
	max-width: 1140px;
}

.titleItalic {
	font-size: 24px;
	font-style: italic;
	margin-bottom: 10px;
	display: block;
	font-weight: 400;
	line-height: 1.6;
	color: #8f9397;
}

.titleSlider {
	font-size: 3rem;
	color: #fff;
	max-width: 70%;
	margin: 0 auto;
}

.sutitleSlider {
	font-size: 1.5rem;
	color: #ffffff;
	font-style: italic;
}

.titleItalic {
	font-family: "Playfair Display", serif;
	font-size: 24px;
	font-style: italic;
	margin-bottom: 10px;
	display: block;
}

.categoryNewsTop {
	padding: 1px 10px;
	color: #ffffff;
	border-radius: 3px;
	position: absolute;
	top: 28px;
	left: 20px;
}

.marginBottom-15 {
	margin-bottom: 15px;
}
.searchInNews {
	border: 1px solid #065596;
	border-radius: 3px;
	margin-bottom: 3px;
}
.newsletterBox {
	background-color: white;
	padding: 30px;
}
.sticky {
	max-width: 320px !important;
	width: 100%;
}
.bottom-20 {
	margin-bottom: 20px;
}
.ctaBox {
	background: linear-gradient(150deg, #e89f14 0%, #915d09 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-one', endColorstr='$color-two', GradientType=1);
	padding: 30px;
}
.ctaTerms {
	margin-top: 5px;
}
.react-multiple-carousel__arrow--left {
	left: calc(0% + 1px);
}

.react-multiple-carousel__arrow--right {
	right: calc(0% + 1px);
}

.react-multiple-carousel__arrow {
	position: absolute;
	outline: 0;
	transition: all 0.5s;
	border-radius: 1px;
	z-index: 20;
	border: 0;
	background: rgba(0, 0, 0, 0.5);
	min-width: 20px;
	min-height: 20px;
	padding: 0px 0px !important;
	opacity: 1;
	cursor: pointer;
	top: 55%;
}

.previousButton,
.nextButton {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	z-index: 10;
	width: 40px;
	height: 50px;
	text-indent: -9999px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.47);
}

.previousButton {
	background: url(/static/media/back.a86bf6c8.svg) no-repeat center center / 16px;
}

.nextButton {
	background: url(/static/media/next.8e8b59b6.svg) no-repeat center center / 16px;
}

.previousButton:hover {
	background: url(/static/media/back.a86bf6c8.svg) no-repeat center center / 16px;
}

.nextButton:hover {
	background: url(/static/media/next.8e8b59b6.svg) no-repeat center center / 16px;
}

.nextButton:hover,
.previousButton:hover {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	z-index: 10;
	width: 40px;
	height: 50px;
	text-indent: -9999px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.47);
}

.boxResumeNews h5 {
	margin-top: 5px;
	margin-bottom: 5px;
	font-size: 16px;
	line-height: 1.2em;
}

.boxResumeNews h6 {
	margin-top: 10px;
}
.boxResumeNews h5 {
	line-height: 1.1;
	margin-bottom: 5px;
}
.sidebarVideo {
	margin-top: 20px;
	margin-bottom: 20px;
}
.sidebarVideo .button {
	width: 100% !important;
}
.videoContent {
	text-align: center;
}
.customButtonArea .button {
	margin-top: 10px;
}
.boxResumeNews button {
	margin-top: 5px;
}

.lastMinuteNews .introText {
	margin-bottom: 10px;
}

.margin-top-100 {
	margin-top: 100px;
}

.margin-top-20 {
	margin-top: 20px;
}

.ctaSection {
	margin-bottom: -100px;
	background-color: #f7f8f9;
	margin-top: -30px;
}

.sidebarListingNumber ul {
	padding-left: 0px !important;
}

.sidebarListingNumber li {
	list-style: none;
	padding: 0.425rem 0;
}

.numberListing {
	background: #f0f1f3;
	color: #8f9397;
	font-weight: 600;
	height: 22px;
	width: 22px;
	line-height: 22px;
	text-align: center;
	display: block;
	border-radius: 50%;
	float: left;
	margin-right: 10px;
	margin-top: 1px;
	font-size: 11px;
}

.titleSidebar {
	margin-bottom: 15px;
	margin-top: 10px;
	font-size: 1.09375rem;
}

.sidebar a {
	color: #8f9397;
}

.sidebar .button a {
	color: #fff;
}

.form-control {
	display: block;
	width: 100%;
	height: auto;
	padding: 0.5rem 1rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.6;
	color: #495057;
	background-color: #ffffff;
	background-clip: padding-box;
	border: 2px solid #dfe2e5;
	border-radius: 3px;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}

.sidebar .registrationNewsletter {
	height: 42px;
	border-radius: 0px;
}

.widgetImage {
	width: 60px;
	height: auto;
	display: table-cell;
}

.widgetImage img {
	width: 100%;
}

.widgetDetails {
	display: table-cell;
	vertical-align: top;
	padding-left: 15px;
	text-align: left;
}

.widgetDetails a {
	font-size: 0.875rem;
	line-height: 1.6;
	display: block;
	margin-bottom: 0px;
	color: #343a40;
	font-weight: 600;
}
.listcomites {
	overflow-y: scroll;
	max-height: 200px;
}
.widget {
	margin: 0;
	padding: 10px 0;
	display: block;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tags a {
	border: 1px solid rgba(0, 0, 0, 0.1);
	font-size: 0.8125rem;
	padding: 5px 15px;
	display: inline-block;
	margin-right: 5px;
	margin-bottom: 10px;
	border-radius: 3px;
	color: #8f9397;
}

.social-icons i {
	color: #fff;
}

.social-icons .social-icons-item {
	display: inline-block;
	list-style: none;
	line-height: 10px;
	margin: 0 5px 5px 0;
}

.social-icons-link {
	display: inline-block;
	height: 32px;
	width: 32px;
	line-height: 32px;
	font-size: 16px;
	text-align: center;
	border-radius: 3px;
	border: 0;
	background: transparent;
	color: #343a40;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}

.social-facebook {
	background-color: #5d82d1;
}

.social-twitter {
	background-color: #40bff5;
}

.social-linkedin {
	background-color: #238cc8;
}

.postNavigation .allPost {
	text-align: center;
	display: block;
	padding-top: 10px;
	color: #343a40;
}

.postNavigation a:hover {
	text-decoration: none;
}

body {
	font-family: "Montserrat", sans-serif;
	font-size: 16px;
}
.slider-content {
	background-size: cover !important;
	background-position: top !important;
}
.container {
	max-width: 1070px;
	margin: 0 auto;
}

header > h1 {
	text-align: center;
}
.sidebarProducts {
	position: fixed;
}
.project-filter-buttons {
	text-align: center;
}
.comiteCheckbox {
	width: 100%;
}
.project-filter-buttons button {
	margin: 5px;
	padding: 10px;
	border: none;
	border-radius: 5px;
	background: yellow;
	cursor: pointer;
	outline: none;
}

.project-filter-buttons button:hover {
	background: orange;
}

.hidden {
	display: none;
}

.active {
	display: block;
}

.categories-button button {
	width: 100%;
}

button.clear-button {
	border: 1px solid grey;
	background: #fff;
}

button.clear-button:hover {
	background: #ddd;
}

.project-filter-buttons button.active-filter {
	background: #1ccccd;
}

.projects-grid {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.project {
	min-height: 400px;
	min-width: 250px;
	width: 31%;
	margin: 10px;
}

.project-image {
	width: 100%;
}

.project-title {
	text-align: center;
}

.project > .badges {
	margin: 5px;
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
}

.project > .badges > span {
	border: 1px solid grey;
	border-radius: 5px;
	padding: 5px 10px 5px 20px;
	position: relative;
	margin: 5px;
}

.project > .badges > span:before {
	position: absolute;
	content: "°";
	font-size: 30px;
	top: 4px;
	left: 5px;
}

.backgroundDark:before {
	background: rgba(0, 0, 0, 0.5);
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: " ";
	width: 100%;
	height: 100%;
	z-index: 0;
}

.filterCategories {
	font-size: 11px;
}

.logoFooter {
	margin-top: 10px;
}

.onlyMobile {
	display: none !important;
}

.onlyDesktop {
	display: block;
}

.InsideDropdown .dropdown-menu {
	left: 100%;
	top: 0;
}

.dropdown-menu {
	min-width: 13rem !important;
	border-radius: 0px !important;
	border-top: 3px solid #045596 !important;
}

.imagePlaceholder {
	width: 100%;
}

.collapsible {
	position: relative;
	display: block;
	padding: 15px 40px 15px 15px;
	color: #343a40;
	background-color: #f0f1f3;
	width: 100%;
	text-align: left;
	transition: all 0s ease-in-out;
	border-radius: 3px;
}
.blueCollapsible {
	color: #fff;
	background-color: #0058a1;
}
.collapsible:hover {
	background-color: #0058a1;
	color: #fff;
}

.noCollapsed:before {
	content: "\f067";
	font-family: "Fontawesome";
	position: absolute;
	top: 50%;
	line-height: 1.8;
	font-weight: bolder;
	font-size: 10px;
	right: 8px;
	padding: 15px;
	color: #343a40;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.goog-te-banner-frame {
	top: 140px !important;
	z-index: 10 !important;
}
.changeTop {
	top: 100px !important;
}
.collapsed:before {
	content: "\f068";
	font-family: "Fontawesome";
	position: absolute;
	top: 50%;
	line-height: 1.8;
	font-weight: bolder;
	font-size: 10px;
	right: 8px;
	padding: 15px;
	color: #343a40;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.blueCollapsible:before {
	color: #fff;
}

.content_collapsible {
	padding: 0 18px;
	display: none;
	overflow: hidden;
	background-color: #f1f1f1;
}

.align-self-center {
	align-self: center !important;
}

.react-tabs__tab--selected {
	background: #0058a1 !important;
	border-color: #0058a1 !important;
	color: #fff !important;
	border-radius: 3px 3px 0 0 !important;
}

.react-tabs__tab-list li {
	font-family: "Montserrat", sans-serif;
	font-size: 0.875rem !important;
	width: 20%;
	text-align: center;
}

.logoComite {
	max-width: 100px;
}

.tabsProfile .react-tabs__tab-list li {
	width: auto;
}

.sidebarListingNumber {
	padding-left: 0px;
}
.canalNoticias li {
	padding-right: 10px;
	padding-left: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.canalNoticias li .new-cat {
	background-color: #fff;
	height: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
}
.canalNoticias .customButtonArea .button {
	position: absolute;
	bottom: 0;
	width: 100%;
	left: 0;
}
.canalNoticias li .new-cat {
	background-color: #fff;
	height: 100%;
}
.canalNoticias .shortDescription {
	margin-bottom: 50px;
}
.canalNoticias .customButtonArea {
	position: absolute;
	width: 100%;
	max-width: 320px;
	bottom: 0px;
	margin-bottom: 5px;
}
.canalNoticias .shadow {
	box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0.15) !important;
}
.socialNetworkFooter,
.marginLittle {
	margin-left: 3px;
	margin-right: 3px;
}
.socialFooter {
	margin-left: 5px;
	margin-right: 5px;
}
.buttonSidebar {
	margin-bottom: 20px;
}
.topic {
	margin-top: 10px;
	margin-bottom: 10px;
}
.boxInfoPresentation {
	margin-top: 20px;
	position: relative;
	width: 30%;
	float: left;
	margin: 5px;
	padding: 5px;
	display: flex;
	flex-direction: column;
	background-color: #f7f8f9;
}
.ContentInformation {
	width: 100%;
	height: 100%;
}
.ContentInformation h4 {
	font-size: 0.9rem;
	font-weight: bold;
}
.ContentInformation p {
	font-size: 0.7rem;
}
.allBoxesPresentation {
	display: flex;
	flex-wrap: wrap;
}
.buttonInformation {
	text-align: center;
}
.buttonInformation .button {
	width: 100%;
}
.underlinetitles {
	border-bottom: 3px solid #045596;
}
.underlinesubtitle {
	border-bottom: 1px solid #045596;
}
.anchor {
	display: block;
	position: relative;
	top: -150px;
	visibility: hidden;
}
.btn-primary:disabled {
	cursor: not-allowed;
}
.button:disabled {
	cursor: not-allowed;
	background: linear-gradient(150deg, #5180a7 0%, #7ea6c1 50%, #628eb3 100%);
	border-color: #7592b1;
}
.product-Title {
	margin-top: 20px;
	font-family: "Montserrat", sans-serif;
}
.memberBlock {
	margin-bottom: 15px;
}
.afiliationPage h2 {
	font-family: "Montserrat", sans-serif;
	font-size: 30px;
	text-transform: uppercase;
}
.afiliationPage h1 {
	font-family: "Montserrat", sans-serif;
	margin-top: 20px;
}
.afiliationPage h4 {
	margin-top: 20px;
}
.goListing {
	margin-top: 20px;
}
.goListing:hover {
	text-decoration: none;
}
.socialIconsTop {
	font-size: 18px;
}
.margin-bottom-20 {
	margin-bottom: 20px;
}
.customTextBoxIcon {
	width: 100%;
	display: flex;
	position: relative;
	clear: both;
	border: 1px solid #b1b1b1;
	margin-top: 5px;
	margin-bottom: 5px;
	padding: 5px;
}
.customTextBoxIcon p {
	margin-bottom: 0rem;
}
.customTextBoxIcon .icono {
	float: left;
	margin-right: 15px;
}
.customTextBoxIcon .textSide {
	float: left;
}
.customTextBoxIcon .textSide .inside {
	position: relative;
	width: 100%;
	display: inline-block;
}
.customTextBoxIcon .textSide .inside h6 {
	font-size: 1.1rem;
	font-weight: bold;
}
.featuredNews {
	margin-bottom: 30px;
}
.canalNews {
	padding-left: 0px !important;
	padding-right: 0px !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
.post-prev::before {
	content: "\A";
	border-style: solid;
	border-width: 10px 10px 10px 0;
	border-color: transparent #065494 transparent transparent;
	position: absolute;
	left: -5px;
	top: 10px;
}
.post-next::after {
	content: "\A";
	border-style: solid;
	border-width: 10px 10px 10px 10px;
	border-color: transparent transparent transparent #065494;
	position: absolute;
	right: -5px;
	top: 10px;
}
.post-next h6 {
	padding-right: 10px;
}
.colorA {
	background-color: #e8eaff;
	padding: 10px;
	margin: 10px;
}
.colorB {
	background-color: #ffd484;
	padding: 10px;
	margin: 10px;
}
.nameSidebarNews {
	max-width: 75%;
	float: left;
}
.buttonSidebarNews {
	max-width: 25%;
	float: left;
	padding: 0.4rem 1rem !important;
}
.clear {
	clear: both;
}
.tableComiteGrupos {
	display: table !important;
}
.tableComiteGrupos .colLogo,
.tableComiteGrupos .colTitle {
	display: table-cell !important;
	vertical-align: middle !important;
}
.tableComiteGrupos .colLogo {
	width: 80px;
}
.smallText {
	font-size: 11px;
}
.smallTextExplanation {
	font-size: 11px;
	margin-bottom: 0.1rem !important;
}
.fieldUnderText {
	margin-bottom: 0.1rem !important;
}
.miembrosIndividuales,
.miembrosColectivos {
	background-color: #ececec;
	padding: 10px;
}
.collapsed:before {
	content: "\f068";
	font-family: "Fontawesome";
	position: absolute;
}
.imgAsociaciones {
	max-width: 75px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}
.comiteTableProfile tbody {
	width: 100%;
	display: contents;
}
.alert-warning p {
	font-size: 15px;
}
.alert-warning {
	margin-top: 10px;
}
.dateAfiliations {
	white-space: nowrap;
	font-size: 14px;
}
.nav-tabs .nav-link.active {
	color: #ffffff !important;
	background-color: #045699 !important;
	border-color: #dee2e6 #dee2e6 #fff;
}
.tabsProfile {
	margin-bottom: 10px;
}
.columnIndexProducts h5 {
	font-weight: 800;
}
.remarkText {
	color: #0058a1;
}
.profileSidebar p {
	line-height: 1;
	font-size: 12px;
	word-break: break-word;
}
.infoResumeOrder {
	margin-bottom: 0.1rem;
	line-height: 1.2;
}
.tox-notification {
	display: none !important;
}
.centerloader {
	margin: 0 auto;
	text-align: center;
}
.adminComitesSidebar {
	padding: 5px;
	border: 1px solid #e79f18;
}
.boxElection {
	padding: 10px;
	border: 1px solid black;
}
.BoxComitePresentCan {
	border: 1px solid #e79f18;
	margin-top: 30px;
	padding: 20px;
	box-shadow: 2px 10px 16px -2px rgba(156, 156, 156, 0.89);
}
.titleComitePresentCan {
	font-size: 20px;
}
.resetFilter {
	font-size: 15px;
}
.widgetImageSidebar {
	width: auto;
	height: auto;
	display: block;
	margin-bottom: 10px;
}
.widgetImageSidebar img {
	width: 100%;
}
.widgetDetailsSidebar {
	display: block;
	text-align: left;
}
.marginButtonDelete {
	margin-top: 22px;
}
.descriptionNew {
	font-size: 12px;
}

.searchItem {
	width: 100%;
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
	float: left;
	margin-top: 10px;
	margin-bottom: 10px;
	min-height: 77px;
}
.searchInfo {
	width: 70%;
	float: left;
}
.searchInfo h4 {
	padding-left: 10px;
	padding-top: 10px;
	word-break: break-word;
}
.searchInfo p {
	padding-left: 10px;
	padding-bottom: 10px;
	word-break: break-word;
}
.searchLink {
	width: 30%;
	float: left;
	margin: 0;
	right: 10px;
	position: relative;
	top: 50%;
	-webkit-transform: translateY(50%);
	        transform: translateY(50%);
}
.searchLink a {
	float: right;
	margin: 0;
	right: 10px;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(50%);
	        transform: translateY(50%);
}
.tablePopUp,
.tablePopComite {
	width: 100%;
	display: inline-table;
	margin-bottom: 10px;
}
.tablePopUp th,
.tablePopUp td,
.tablePopComite th,
.tablePopComite td {
	border: 1px solid #e5e5e5;
	padding: 3px;
}
.tablePopComite tbody tr:nth-of-type(odd),
.tablePopUp tbody tr:nth-of-type(odd) {
	background-color: rgba(0, 0, 0, 0.05);
}
.hideInDesktop {
	display: none !important;
}

#google_translate_element {
	width: 50%;
	float: right;
	margin-top: -29px;
	background-color: transparent !important;
}

/* RESPONSIVE */
@media only screen and (max-width: 991px) {
	.hideInDesktop {
		display: block !important;
	}
	#google_translate_element {
		width: 100%;
	}
	.customPaddingTop,
	.topMenu {
		padding-left: 5px !important;
		padding-right: 5px !important;
	}
	.dropdownOptionsMenu .dropdown-menu {
		left: -65px;
		position: absolute;
		width: 49px;
	}
}
@media only screen and (max-width: 768px) {
	.hideInMobile {
		display: none;
	}
	.submenuOptions {
		font-size: 10.5px;
	}
	.noticeInfo img {
		width: 100%;
		height: auto;
	}
	.mailTop {
		display: block;
	}
	.nameTopDesktop {
		display: none;
	}
	.tabsTemplate {
		display: inline-flex !important;
		flex-wrap: wrap;
	}
	.onlyMobile {
		display: block !important;
	}
	.onlyDesktop {
		display: none;
	}
	.react-tabs__tab-list li {
		width: 50%;
	}
	.titleSlider,
	.noticeTitle h1 {
		font-size: 1.5rem;
	}

	.sutitleSlider,
	.subtitle {
		font-size: 0.9rem;
	}
	.h-sm-100-vh {
		height: auto;
	}
	.seeVideo .button {
		position: relative;
		bottom: 0px;
		left: 0px;
		margin-left: 0px;
	}
	.boxInfoPresentation {
		position: relative;
		width: 100%;
		float: left;
	}
	.ContentInformation {
		float: left;
		width: 100%;
	}

	.buttonInformation {
		float: left;
		width: 100%;
		text-align: center;
	}
	.buttonInformation .button {
		width: 100%;
	}
	.categoryListing,
	.lastnewsSidebar,
	.newsletterSidebar {
		display: none;
	}
	.searchInfo {
		width: 100%;
		float: none;
	}
	.searchLink {
		width: 100%;
		float: none;
		top: 0%;
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
		right: 0px;
	}
	.searchLink a {
		float: none;
		width: 100%;
		margin: 0;
		right: 0px;
		position: relative;
		top: none;
		-webkit-transform: translateY(0%);
		        transform: translateY(0%);
		margin-bottom: 5px;
	}
	.contactInfoTop p {
		font-size: 11px;
	}
}

@media only screen and (max-width: 640px) {
	.slider-wrapper,
	.slide {
		height: calc(80vh - 75px);
	}
	.topMenuRight .navbar-nav .nav-link {
		padding-right: 0.2rem !important;
		padding-left: 0.2rem !important;
	}
	.logoHeader {
		width: 140px;
	}
	.topMenuRight,
	.topMenuLeft {
		font-size: 0.75rem;
	}
	.topMenuLeft {
		padding-top: 0px;
		padding-left: 0px !important;
	}

	.topHeaderSocial {
		display: none !important;
	}
}



.ResumeComite {
	display: flex;
}
.boxResumeComite {
	flex: 1 1;
	min-height: 85px;
}
.numberResumen {
	position: absolute;
	left: 50%;
	top: 100%;
	-webkit-transform: translate(-50%, -100%);
	        transform: translate(-50%, -100%);
	font-size: 30px;
}
.editNew {
	display: none;
}
.hidden {
	display: none;
}

.warning-container {
	margin-bottom: 15px;
}

.image-warning {
	font-size: 14px;
	font-style: italic;
}

